import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import Image from '../assets/img/house-banner3.png';
import Search from '../components/Search';

const Banner = () => {
  return (
    <section className='h-full max-h-[640px] mb-8 xl:mb-124 '>
      <div className='flex flex-col lg:flex-row'>
        <div className='lg:ml-8 xl:ml-[135px] flex flex-col items-center lg:items-start text-center lg:text-left justify-center flex-1 px-4 lg:px-0'>
          <h1 className='text-4xl lg:text-[58px] font-semibold leading-none mb-6'>
            <span className='text-[#2376c0]'><ReactTypingEffect text={["Invista", "More"]} speed='100' /></span> em Casas Luxuosas no Litoral Catarinense
          </h1>
          <p className='max-w-[480px] mb-8'>
            Tecnológicas, sustentáveis, elegantes e com a assinatura de arquitetos renomados, esses são os empreendimentos
            da Polaris!
          </p>
        </div>
        <div className='hidden flex-1 lg:flex justify-end items-end'>
          <img src={Image} alt='' />
        </div>
      </div>
      <Search />
    </section>
  );
};

export default Banner;

import React, { useContext } from 'react';

import CountryDropdown from './CountryDropdown';
import PriceRangeDropdown from './PriceRangeDropdown';
import StatusDropdown from './StatusDropdown';

import { HouseContext } from './HouseContext';

import { RiSearch2Line } from 'react-icons/ri';

const Search = () => {
  const { handleClick } = useContext(HouseContext);
  return (
    <div className='z-10 px-[30px] py-6 max-w-[900px] mx-auto flex flex-col lg:flex-row justify-between gap-4 lg:gap-x-3 relative lg:-top-4 lg:shadow-1 bg-white lg:bg-transparent lg:backdrop-blur rounded-lg'>
      <CountryDropdown />
      {/* <PriceRangeDropdown /> */}
      <StatusDropdown />
      <button
        onClick={() => {
          handleClick();
        }}
        className='bg-[#141651] hover:bg-[#2fa8a5] transition w-full lg:max-w-[162px] h-16 rounded-lg flex justify-center items-center text-white text-lg'
      >
        <RiSearch2Line />
      </button>
    </div>
  );
};

export default Search;

import React, { useState } from 'react';
import { ComuSliderData } from '../data';
import comuPDF from '../pdf/vivapark.pdf'
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';

const ComuSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState(null);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const handleImageClick = (src) => {
    setShowModal(true);
    setModalImageSrc(src);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalImageSrc(null);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <section className='relative'>
  <div className='slider'>
    {ComuSliderData.map((slide, index) => {
      return (
        <div
          className={index === current ? 'slide active' : 'slide'}
          key={index}
        >
          {index === current && (
            <img
              src={slide.image}
              alt='viva park'
              className='image'
              onClick={() => handleImageClick(slide.image)}
            />
          )}
        </div>
      );
    })}
  </div>
  <div className='absolute top-1/2 -mt-4 z-10 left-0'>
    <FaArrowAltCircleLeft
      className='h-8 w-8 cursor-pointer text-white ml-2'
      onClick={prevSlide}
    />
  </div>
  <div className='absolute top-1/2 -mt-4 z-10 right-0'>
    <FaArrowAltCircleRight
      className='h-8 w-8 cursor-pointer text-white mr-2'
      onClick={nextSlide}
    />
  </div>
  {showModal && (
    <div className='fixed inset-0 z-50 flex items-center justify-center'>
      <div
        className='absolute inset-0 bg-gray-900 opacity-0'
        onClick={closeModal}
      ></div>
      <div className='bg-white rounded-lg p-4 max-w-full max-h-full overflow-auto'>
        <img
          src={modalImageSrc}
          alt='viva park'
          className='max-w-full max-h-full'
        />
      </div>
    </div>
  )}
  <div className='absolute bottom-0 right-0 mb-4 mr-4'>
    <a href={comuPDF} download='vivapark-polarisempreendimentos.pdf' className='bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
      Baixe essa apresentação
    </a>
  </div>
</section>
  );
};

export default ComuSlider;

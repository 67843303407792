import emailjs from "emailjs-com";
import React, { useRef } from 'react'
import { TabTitle } from "../utils/GeneralFunctions";


const Contact5 = () => {
  TabTitle('Entre em contato | Polaris Empreendimentos');

  const nameRef = useRef()
  const emailRef = useRef()
  const messageRef = useRef()

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('site_polaris', 'polaris_emailjs_formcasa', e.target, '-ZykkYn2ZaJN1SGtS')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  return (
    <div className="relative mx-auto w-full max-w-7xl bg-white text-gray-700">
      <div className="grid grid-cols-2">
        
        {/* :MAP CONTAINER */}
        <div className="order-1 col-span-full">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3550.3371862502013!2d-48.50889768464096!3d-27.14567928302781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8a5f73c4c0c41%3A0xb58de27387800d45!2sPolaris%20Empreendimentos!5e0!3m2!1spt-BR!2sbr!4v1664222995256!5m2!1spt-BR!2sbr" 
            title="map" scrolling="no" frameborder="0"
            width="100%" height="300px"
            className="" 
            loading="lazy" 
          />
        </div>



        {/* :CONTACT FORM CONTAINER */}
        <div className="order-3 md:order-2 col-span-full md:col-span-1 py-5 md:py-10 px-6">
          <form action="" className="mx-auto max-w-xl space-y-4" onSubmit={sendEmail}>
            {/* ::Name Input */}
            <div>
              {/* :::label */}
              <label htmlFor="name" className="sr-only">Nome</label>
              {/* :::input */}
              <input ref={nameRef} type="text" id="name" name="name"
                placeholder="Nome"
                className="pl-2 form-input w-full block shadow-sm rounded border-gray-300 bg-gray-100 text-base placeholder-gray-400 focus:border-green-400 focus:ring-1 focus:ring-green-400"
                required
              />
            </div>
            {/* ::Email Input */}
            <div>
              {/* :::label */}
              <label htmlFor="email" className="sr-only">Email</label>
              {/* :::input */}
              <input ref={emailRef} type="email" id="email" name="email"
                placeholder="Email"
                className="pl-2 form-input w-full block shadow-sm rounded border-gray-300 bg-gray-100 text-base placeholder-gray-400 focus:border-green-400 focus:ring-1 focus:ring-green-400"
                required
              />
            </div>
            {/* ::Message Input */}
            <div className="col-span-full">
              {/* :::label */}
              <label htmlFor="message" className="sr-only">Mensagem</label>
              {/* :::input */}
              <textarea ref={messageRef} name="message" id="message" cols="30" rows="4"
                placeholder="Como podemos te ajudar?"
                className="pl-2 form-textarea resize-none w-full shadow-sm rounded border-gray-300 bg-gray-100 placeholder-gray-400 placeholder-pl-1 focus:border-green-400 focus:ring-green-400"
                required
              ></textarea>
            </div>
            {/* ::Submit Button */}
            <div>
              <input type="submit" value="Enviar Mensagem" className="py-2 px-6 rounded bg-blue-900 text-base text-white font-semibold hover:bg-blue-700"></input>
            </div>
          </form>
        </div>



        {/* :CONTACT INFOS CONTAINER */}
        <div className="order-2 md:order-3 col-span-full md:col-span-1 py-5 md:py-10 px-6">
          <div className="mx-auto max-w-xl flex flex-col space-y-5">
            {/* ::Title Contact Us */}
            <h2 className="text-4xl">Fale conosco</h2>
            {/* ::Text */}
            <p className="text-sm text-gray-500">Nós enviaremos o seu contato para um dos nossos representates cadastrados, para garantir um atendimento de qualidade!</p>
            {/* ::Email contact */}
            <a href="#mail" className="inline-flex items-center text-sm text-blue-900 font-semibold hover:text-blue-700">
                contato@polarisempreendimentos.com
            </a>
            
          </div>
        </div>

      </div>
    </div>
  )
}

export default Contact5

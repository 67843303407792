import React from 'react';
import { BiBed, BiBath, BiArea } from 'react-icons/bi';
import { RiHome5Line } from 'react-icons/ri';
import { AiOutlineCar } from 'react-icons/ai';
import ModernButton from './InfoButtons';
import overlappingImage from '../assets/img/GBC.png';
import { useLocation } from 'react-router-dom';


const House = ({ house }) => {
  const location = useLocation();


  return (
    <div className='relative bg-white shadow-1 p-5 rounded-lg rounded-tl-[90px] w-full max-w-[552px] mx-auto cursor-pointer hover:shadow-2xl transition'>
      {house.isNew && <div className='absolute top-0 left-0 bg-[#2376c0] text-white px-2 py-1 rounded-lg'>Novo</div>}
      <img className='mb-8 max-w-[500px] max-h-[500px] hidden md:block' src={house.image} alt='' />
      <img className='mb-8 md:hidden' src={house.imageMobile} alt='' />
      <div className='mb-4 flex gap-x-2 text-sm'>
        <div className='bg-[#141651] rounded-full text-white px-3 inline-block'>
          {house.country}
        </div>
        <div className='bg-red-500 rounded-full text-white px-2 inline-block empty:hidden'>
          {house.tag}
        </div>
        <div className={`rounded-full text-white px-2 inline-block empty:hidden ${house.isAvailable === "Disponível" ? 'bg-[#2fa8a5]' : 'bg-red-500'}`}>
        {house.isAvailable}
        </div>
      </div>
      <div className='text-lg font-semibold max-w-[260px]'>{house.name}</div>
      <div className='flex gap-x-4 my-4'>
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px] rounded-full'>
            <BiBed />
          </div>
          <div className='text-base'>{house.bedrooms}</div>
        </div>
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px] rounded-full'>
            <BiBath />
          </div>
          <div className='text-base'>{house.bathrooms}</div>
        </div>
        <div className='flex items-center text-gray-600 gap-1'>
          <div className='text-[20px] rounded-full'>
            <BiArea />
          </div>
          <div className='text-base'>{house.surface}</div>
        </div>
        <div className='flex items-center text-gray-600 gap-1 '>
          <div className='text-[20px] rounded-full '>
            <RiHome5Line />
          </div>
          <div className='text-base'>{house.built}</div>
        </div>
        <div className='flex items-center text-gray-600 gap-1 '>
          <div className='text-[20px] rounded-full '>
            <AiOutlineCar />
          </div>
          <div className='text-base'>{house.garage}</div>
        </div>
      </div>
      <div className='text-lg font-semibold text-[#2376c0] mb-4'>
        {/* R$ {house.pricepage} */} <ModernButton />
      </div>
      
      {/* {location.pathname === '/' && house.certificate && (
      <img
        className="absolute top-64 right-14 transform translate-x-1/2 -translate-y-1/2 w-20 h-20"
        src={overlappingImage}
        alt="Overlapping"
      /> )} */}

    </div>
  );
};

export default House;

// import house images
import EcchoHouse from "./assets/img/houses/eccho11.png";
import FioreHouse from "./assets/img/houses/fiore11.png";
import LuminaHouse from "./assets/img/houses/lumina11.png";
import ReggiaHouse from "./assets/img/houses/reggia11.png";
import Vivendas from "./assets/img/houses/vivendas11.png";
import OasisHouse from "./assets/img/houses/oasis11.png";
import BrizHouse from "./assets/img/houses/briz11.png";
import CortenHouse from "./assets/img/houses/corten11.png";
import CerneHouse from "./assets/img/houses/cerne11.png";
import NaturalHouse from "./assets/img/houses/natural11.png";
import CosmoHouse from "./assets/img/houses/cosmopolita/cosmo.png";


// import house images Mobile
import EcchoHouseMobile from "./assets/img/houses/echo.png";
import FioreHouseMobile from "./assets/img/houses/fiore.png";
import LuminaHouseMobile from "./assets/img/houses/lumina.png";
import ReggiaHouseMobile from "./assets/img/houses/reggia.png";
import VivendasMobile from "./assets/img/houses/vivendas.png";
import OasisHouseMobile from "./assets/img/houses/oasis.png";
import BrizHouseMobile from "./assets/img/houses/briz.png";
import CortenHouseMobile from "./assets/img/houses/corten.png";
import CerneHouseMobile from "./assets/img/houses/cerne.png";
import NaturalHouseMobile from "./assets/img/houses/natural.png";
import CosmoHouseMobile from "./assets/img/houses/cosmopolita/cosmomobile.png";

//import house location
import EcchoLocation from "./assets/img/houses/echo-5.jpg";
import FioreLocation from "./assets/img/houses/fiore-1.jpg";
import LuminaLocation from "./assets/img/houses/lumina-8.jpg";
import ReggiaLocation from "./assets/img/houses/reggia-8.jpg";
import VivendasLocation from "./assets/img/houses/vivendas-5.jpeg";
import OasisLocation from "./assets/img/houses/oasis-19.jpg";
import BrizLocation from "./assets/img/houses/briz-location.jpg";
import CerneLocation from "./assets/img/houses/cerne-location.jpg";
import CortenLocation from "./assets/img/houses/corten-location.jpg";
import NaturalLocation from "./assets/img/houses/natural-location.jpg";

// import house large images
import Eccho1Lg from "./assets/img/houses/echo-1.jpeg";
import Eccho2Lg from "./assets/img/houses/echo-2.jpg";
import Eccho3Lg from "./assets/img/houses/echo-3.jpeg";
import Eccho4Lg from "./assets/img/houses/echo-4.jpeg";
import Eccho6Lg from "./assets/img/houses/echo-6.jpg";
import Eccho7Lg from "./assets/img/houses/echo-7.png";
import Eccho8Lg from "./assets/img/houses/echo-8.jpeg";
import Eccho9Lg from "./assets/img/houses/echo-9.jpg";
import Eccho10Lg from "./assets/img/houses/echo-10.jpg";
import Fiore1Lg from "./assets/img/houses/fiore-2.jpg";
import Fiore3Lg from "./assets/img/houses/fiore-3.png";
import Fiore4Lg from "./assets/img/houses/fiore-4.png";
import Fiore5Lg from "./assets/img/houses/fiore-5.png";
import Fiore6Lg from "./assets/img/houses/fiore-6.png";
import Fiore7Lg from "./assets/img/houses/fiore-7.jpg";
import Fiore8Lg from "./assets/img/houses/fiore-8.png";
import Fiore9Lg from "./assets/img/houses/fiore-9.jpg";
import Fiore10Lg from "./assets/img/houses/fiore-10.png";
import Lumina1Lg from "./assets/img/houses/lumina-5.jpg";
import Lumina2Lg from "./assets/img/houses/lumina-1.jpg";
import Lumina3Lg from "./assets/img/houses/lumina-3.jpg";
import Lumina4Lg from "./assets/img/houses/lumina-4.jpg";
import Lumina5Lg from "./assets/img/houses/lumina-2.jpg";
import Lumina6Lg from "./assets/img/houses/lumina-6.jpg";
import Lumina7Lg from "./assets/img/houses/lumina-7.jpg";
import Reggia1Lg from "./assets/img/houses/reggia-4.jpg";
import Reggia2Lg from "./assets/img/houses/reggia-1.jpg";
import Reggia3Lg from "./assets/img/houses/reggia-2.jpg";
import Reggia4Lg from "./assets/img/houses/reggia-3.jpg";
import Reggia5Lg from "./assets/img/houses/reggia-5.jpg";
import Reggia6Lg from "./assets/img/houses/planta-reggia.png";
import Reggia7Lg from "./assets/img/houses/planta-reggia-2.png";
import Reggia9Lg from "./assets/img/houses/reggia-9.jpg";
import Reggia10Lg from "./assets/img/houses/Reggia_01_FINAL.jpg";
import Reggia11Lg from "./assets/img/houses/Reggia_02_FINAL.jpg";
import Reggia12Lg from "./assets/img/houses/Reggia_03_FINAL.jpg";
import Reggia13Lg from "./assets/img/houses/Reggia_04_FINAL.jpg";
import Reggia14Lg from "./assets/img/houses/Reggia_05_FINAL.jpg";
import Vivendas1Lg from "./assets/img/houses/vivendas-1.jpeg";
import Vivendas2Lg from "./assets/img/houses/vivendas-2.jpeg";
import Vivendas3Lg from "./assets/img/houses/vivendas-3.jpeg";
import Vivendas4Lg from "./assets/img/houses/vivendas-4.jpeg";
import Vivendas6Lg from "./assets/img/houses/vivendas-6.jpeg";
import Vivendas7Lg from "./assets/img/houses/vivendas-7.jpeg";
import Vivendas8Lg from "./assets/img/houses/vivendas-8.jpeg";
import Vivendas9Lg from "./assets/img/houses/vivendas-9.jpg";
import Vivendas10Lg from "./assets/img/houses/vivendas-10.jpg";
import Vivendas11Lg from "./assets/img/houses/vivendas-11.jpg";
import Vivendas12Lg from "./assets/img/houses/vivendas-12.jpg";
import Vivendas13Lg from "./assets/img/houses/vivendas-13.jpeg";
import Oasis1Lg from "./assets/img/houses/oasis-1.jpg";
import Oasis2Lg from "./assets/img/houses/oasis-2.jpg";
import Oasis3Lg from "./assets/img/houses/oasis-3.jpg";
import Oasis4Lg from "./assets/img/houses/oasis-4.jpg";
import Oasis5Lg from "./assets/img/houses/oasis-5.jpg";
import Oasis6Lg from "./assets/img/houses/oasis-6.jpg";
import Oasis7Lg from "./assets/img/houses/oasis-7.jpg";
import Oasis8Lg from "./assets/img/houses/oasis-8.jpg";
import Oasis9Lg from "./assets/img/houses/oasis-9.jpg";
import Oasis10Lg from "./assets/img/houses/oasis-10.jpg";
import Oasis11Lg from "./assets/img/houses/oasis-11.jpg";
import Oasis12Lg from "./assets/img/houses/oasis-12.jpg";
import Oasis13Lg from "./assets/img/houses/oasis-13.jpg";
import Oasis14Lg from "./assets/img/houses/oasis-14.jpg";
import Oasis15Lg from "./assets/img/houses/oasis-15.jpg";
import Oasis16Lg from "./assets/img/houses/oasis-16.jpg";
import Oasis17Lg from "./assets/img/houses/oasis-17.jpg";
import Oasis18Lg from "./assets/img/houses/oasis-18.jpg";
import Corten1Lg from "./assets/img/houses/corten-1.jpg";
import Corten2Lg from "./assets/img/houses/corten-2.jpg";
import Corten3Lg from "./assets/img/houses/corten-3.jpg";
import Corten4Lg from "./assets/img/houses/corten-4.jpg";
import Corten5Lg from "./assets/img/houses/corten-5.jpg";
import Corten6Lg from "./assets/img/houses/corten-6.jpg";
import Corten7Lg from "./assets/img/houses/corten-7.jpg";
import Corten8Lg from "./assets/img/houses/corten-8.jpg";
import Corten9Lg from "./assets/img/houses/corten-9.jpg";
import Corten10Lg from "./assets/img/houses/corten-10.jpg";
import Corten11Lg from "./assets/img/houses/corten-11.jpg";
import Corten12Lg from "./assets/img/houses/corten-12.jpg";
import Corten13Lg from "./assets/img/houses/corten-13.jpg";
import Corten14Lg from "./assets/img/houses/corten-14.jpg";
import Corten15Lg from "./assets/img/houses/corten-15.jpg";
import Corten16Lg from "./assets/img/houses/corten-16.jpg";
import Corten17Lg from "./assets/img/houses/corten-17.jpg";
import Corten18Lg from "./assets/img/houses/corten-18.jpg";
import Corten19Lg from "./assets/img/houses/corten-19.jpg";
import Corten20Lg from "./assets/img/houses/corten-20.jpg";
import Corten21Lg from "./assets/img/houses/corten-21.jpg";
import Corten22Lg from "./assets/img/houses/corten-22.jpg";
import Corten23Lg from "./assets/img/houses/corten-23.jpg";
import Corten24Lg from "./assets/img/houses/corten-24.jpg";
import Corten25Lg from "./assets/img/houses/corten-25.jpg";
import Corten26Lg from "./assets/img/houses/corten-26.jpg";
import CortenFachada from "./assets/img/houses/corten-fachada.jpg";
import Cerne1Lg from "./assets/img/houses/cerne-1.jpg";
import Cerne3Lg from "./assets/img/houses/cerne-3.jpg";
import Cerne4Lg from "./assets/img/houses/cerne-4.jpg";
import Cerne5Lg from "./assets/img/houses/cerne-5.jpg";
import Cerne6Lg from "./assets/img/houses/cerne-6.jpg";
import Cerne7Lg from "./assets/img/houses/cerne-7.jpg";
import Cerne8Lg from "./assets/img/houses/cerne-8.jpg";
import Cerne9Lg from "./assets/img/houses/cerne-9.jpg";
import Cerne10Lg from "./assets/img/houses/cerne-10.jpg";
import Cerne11Lg from "./assets/img/houses/cerne-11.jpg";
import Cerne12Lg from "./assets/img/houses/cerne-12.jpg";
import Cerne13Lg from "./assets/img/houses/cerne-13.jpg";
import Cerne14Lg from "./assets/img/houses/cerne-14.jpg";
import Cerne15Lg from "./assets/img/houses/cerne-15.jpg";
import Cerne16Lg from "./assets/img/houses/cerne-16.jpg";
import Cerne17Lg from "./assets/img/houses/cerne-17.jpg";
import Cerne18Lg from "./assets/img/houses/cerne-18.jpg";
import Cerne19Lg from "./assets/img/houses/cerne-19.jpg";
import Cerne20Lg from "./assets/img/houses/cerne-20.jpg";
import Cerne21Lg from "./assets/img/houses/cerne-21.jpg";
import Cerne22Lg from "./assets/img/houses/cerne-22.jpg";
import Cerne23Lg from "./assets/img/houses/cerne-23.jpg";
import Cerne24Lg from "./assets/img/houses/cerne-24.jpg";
import Cerne25Lg from "./assets/img/houses/cerne-25.jpg";
import Cerne26Lg from "./assets/img/houses/cerne-26.jpg";
import Cerne27Lg from "./assets/img/houses/cerne-27.jpg";
import Cerne28Lg from "./assets/img/houses/cerne-28.jpg";
import Cerne29Lg from "./assets/img/houses/cerne-29.jpg";
import CerneFachada from "./assets/img/houses/cerne-fachada.jpg";
import Briz1Lg from "./assets/img/houses/briz-1.jpg";
import Briz2Lg from "./assets/img/houses/briz-2.jpg";
import Briz3Lg from "./assets/img/houses/briz-3.jpg";
import Briz4Lg from "./assets/img/houses/briz-4.jpg";
import Briz5Lg from "./assets/img/houses/briz-5.jpg";
import Briz6Lg from "./assets/img/houses/briz-6.jpg";
import Briz7Lg from "./assets/img/houses/briz-7.jpg";
import Briz8Lg from "./assets/img/houses/briz-8.jpg";
import Briz9Lg from "./assets/img/houses/briz-9.jpg";
import Briz10Lg from "./assets/img/houses/briz-10.jpg";
import Briz11Lg from "./assets/img/houses/briz-11.jpg";
import Briz12Lg from "./assets/img/houses/briz-12.jpg";
import Briz13Lg from "./assets/img/houses/briz-13.jpg";
import Briz14Lg from "./assets/img/houses/briz-14.jpg";
import Briz15Lg from "./assets/img/houses/briz-15.jpg";
import Briz16Lg from "./assets/img/houses/briz-16.jpg";
import Briz17Lg from "./assets/img/houses/briz-17.jpg";
import Briz18Lg from "./assets/img/houses/briz-18.jpg";
import Briz20Lg from "./assets/img/houses/briz-20.jpg";
import Briz21Lg from "./assets/img/houses/briz-21.jpg";
import BrizFachada from "./assets/img/houses/briz-fachada.jpg";
import Natural1Lg from "./assets/img/houses/natural1.jpg";
import Natural3Lg from "./assets/img/houses/natural3.jpg";
import Natural4Lg from "./assets/img/houses/natural4.jpg";
import Natural5Lg from "./assets/img/houses/natural5.jpg";
import Natural6Lg from "./assets/img/houses/natural6.jpg";
import Natural7Lg from "./assets/img/houses/natural7.jpg";
import Natural8Lg from "./assets/img/houses/natural8.jpg";
import Natural9Lg from "./assets/img/houses/natural9.jpg";
import Natural10Lg from "./assets/img/houses/natural10.jpg";
import NaturalFachada from "./assets/img/houses/natural2.jpg";
import CosmoFachada from "./assets/img/houses/cosmopolita/Cosmopolita.png";
import Cosmo1Lg from "./assets/img/houses/cosmopolita/cosmopolita-1.jpg";
import Cosmo2Lg from "./assets/img/houses/cosmopolita/cosmopolita-2.jpg";
import Cosmo3Lg from "./assets/img/houses/cosmopolita/cosmopolita-3.jpg";
import Cosmo4Lg from "./assets/img/houses/cosmopolita/cosmopolita-4.jpg";
import Cosmo5Lg from "./assets/img/houses/cosmopolita/cosmopolita-5.jpg";
import Cosmo6Lg from "./assets/img/houses/cosmopolita/cosmopolita-6.jpg";
import Cosmo7Lg from "./assets/img/houses/cosmopolita/cosmopolita-7.jpg";


// viva park apresentacao
import Viva1 from "./assets/img/vivapark/viva-01.png"
import Viva2 from "./assets/img/vivapark/viva-02.png"
import Viva3 from "./assets/img/vivapark/viva-03.png"
import Viva4 from "./assets/img/vivapark/viva-04.png"
import Viva5 from "./assets/img/vivapark/viva-05.png"
import Viva6 from "./assets/img/vivapark/viva-06.png"
import Viva7 from "./assets/img/vivapark/viva-07.png"
import Viva8 from "./assets/img/vivapark/viva-08.png"
import Viva9 from "./assets/img/vivapark/viva-09.png"
import Viva10 from "./assets/img/vivapark/viva-10.png"
import Viva11 from "./assets/img/vivapark/viva-11.png"
import Viva12 from "./assets/img/vivapark/viva-12.png"
import Viva13 from "./assets/img/vivapark/viva-13.png"
import Viva14 from "./assets/img/vivapark/viva-14.png"
import Viva15 from "./assets/img/vivapark/viva-15.png"
import Viva16 from "./assets/img/vivapark/viva-16.png"
import Viva17 from "./assets/img/vivapark/viva-17.png"
import Viva18 from "./assets/img/vivapark/viva-18.png"
import Viva19 from "./assets/img/vivapark/viva-19.png"
import Viva20 from "./assets/img/vivapark/viva-20.png"
import Viva21 from "./assets/img/vivapark/viva-21.png"
import Viva22 from "./assets/img/vivapark/viva-22.png"
import Viva23 from "./assets/img/vivapark/viva-23.png"
import Viva24 from "./assets/img/vivapark/viva-24.png"
import Viva25 from "./assets/img/vivapark/viva-25.png"
import Viva26 from "./assets/img/vivapark/viva-26.png"
import Viva27 from "./assets/img/vivapark/viva-27.png"
import Viva28 from "./assets/img/vivapark/viva-28.png"
import Viva29 from "./assets/img/vivapark/viva-29.png"
import Viva30 from "./assets/img/vivapark/viva-30.png"
import Viva31 from "./assets/img/vivapark/viva-31.png"

// import posts
import PostEccho1 from "./assets/img/posts/Eccho/03.jpg";
import PostEccho2 from "./assets/img/posts/Eccho/02.png";
import PostEccho3 from "./assets/img/posts/Eccho/01.png";

import PostFiore1 from "./assets/img/posts/Fiore/01.jpg";
import PostFiore2 from "./assets/img/posts/Fiore/02.jpg";

import PostLumina1 from "./assets/img/posts/Lumina/01.png";
import PostLumina2 from "./assets/img/posts/Lumina/02.png";

import PostReggia1 from "./assets/img/posts/Reggia/03.png";
import PostReggia2 from "./assets/img/posts/Reggia/02.png";
import PostReggia3 from "./assets/img/posts/Reggia/01.png";

import PostVivendas1 from "./assets/img/posts/Vivendas/01.png";
import PostVivendas2 from "./assets/img/posts/Vivendas/02.png";

import PostOasis1 from "./assets/img/posts/Oasis/01.png";
import PostOasis2 from "./assets/img/posts/Oasis/02.jpg";
import PostOasis3 from "./assets/img/posts/Oasis/03.png";

import PostCerne1 from "./assets/img/posts/Cerne/01.jpg";
import PostCerne2 from "./assets/img/posts/Cerne/02.jpg";

import PostBriz1 from "./assets/img/posts/Briz/01.jpg";
import PostBriz2 from "./assets/img/posts/Briz/02.png";
import PostBriz3 from "./assets/img/posts/Briz/03.jpg";

import PostCorten1 from "./assets/img/posts/Corten/01.png";
import PostCorten2 from "./assets/img/posts/Corten/02.png";
import PostCorten3 from "./assets/img/posts/Corten/03.jpg";

import PostNatural1 from "./assets/img/posts/Natural/01.png";
import PostNatural2 from "./assets/img/posts/Natural/02.jpg";
import PostNatural3 from "./assets/img/posts/Natural/03.jpg";

import PostPortoBelo1 from "./assets/img/posts/porto-belo/01.png";


// import agents images
import Agent2 from "./assets/img/agents/agent2.png";

// array of houseId
export const recommendations = [10, 9, 7, 8, 6, 1, 2, 3, 4, 5];

export const ComuSliderData = [
  {
    image: Viva1,
  },
  {
    image: Viva2,
  },
  {
    image: Viva3,
  },
  {
    image: Viva4,
  },
  {
    image: Viva5,
  },
  {
    image: Viva6,
  },
  {
    image: Viva7,
  },
  {
    image: Viva8,
  },
  {
    image: Viva9,
  },
  {
    image: Viva10,
  },
  {
    image: Viva11,
  },
  {
    image: Viva12,
  },
  {
    image: Viva13,
  },
  {
    image: Viva14,
  },
  {
    image: Viva15,
  },
  {
    image: Viva16,
  },
  {
    image: Viva17,
  },
  {
    image: Viva18,
  },
  {
    image: Viva19,
  },
  {
    image: Viva20,
  },
  {
    image: Viva21,
  },
  {
    image: Viva22,
  },
  {
    image: Viva23,
  },
  {
    image: Viva24,
  },
  {
    image: Viva25,
  },
  {
    image: Viva26,
  },
  {
    image: Viva27,
  },
  {
    image: Viva28,
  },
  {
    image: Viva29,
  },
  {
    image: Viva30,
  },
  {
    image: Viva31,
  },
];

export const housesData = [
  {
    id: 1,
    type: "Casa",
    name: "Eccho",
    isAvailable: "Consultar",
    description:
      "Criada a partir de um conceito arquitetônico moderno e com detalhes que encantam, a casa Eccho transborda imponência em cada linha do projeto. São 370m² construídos contando com design em conceito aberto, integralizando ambientes, ampla iluminação natural e ventilação cruzada. Casa com 4 suítes, sendo 1 suíte master, amplo espaço gourmet com piscina planejada, cozinha e sala de jantar integralizados em conceito aberto. São alguns dos detalhes que apaixonam e ambientam esta casa no primeiro bairro parque do Brasil.",
    image: EcchoHouse,
    imageMobile: EcchoHouseMobile,
    fachada: Eccho1Lg,
    carouselData: [
      {
        image: Eccho10Lg,
        video: "",
      },
      {
        image: "",
        video: "https://player.vimeo.com/video/756466271?h=9e884055ab",
      },
      {
        image: Eccho3Lg,
        video: "",
      },
      {
        image: Eccho4Lg,
        video: "",
      },
      {
        image: Eccho6Lg,
        video: "",
      },
      {
        image: Eccho7Lg,
        video: "",
      },
      {
        image: Eccho8Lg,
        video: "",
      },
      {
        image: Eccho9Lg,
        video: "",
      },
      {
        image: Eccho2Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostEccho1,
        video: "",
        url: "https://www.instagram.com/p/CqEHlluPqGv/",
      },
      {
        image: PostEccho2,
        video: "",
        url: "https://www.instagram.com/p/Cid4KketDAK/",
      },
      {
        image: PostEccho3,
        video: "",
        url: "https://www.instagram.com/p/CjRP5Nbuwyt/",
      },
      {
        image: PostPortoBelo1,
        video: "",
        url: "https://www.instagram.com/p/CkHL01uBxRo/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 29 | Lote 07",
    bedrooms: "4",
    tooltip: "3 Suítes e uma Suíte Master",
    bathrooms: "6",
    surface: "390m²",
    built: "370m²",
    price: "4924500",
    pricepage: "4.924.500",
    garage: "2",
    location: EcchoLocation,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Piscina planejada", "Área Goumert", "Selo Leed Platinum", "Conceito Aberto", "Automações", "Amplo Jardim e área verde privativa"],
    certificate: true,
    community: true,
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 2,
    type: "Casa",
    name: "Fiore",
    description:
      "A casa Fiore, traz tons modernos e sóbrios, sua fachada encravada em revestimento marmorizado contrastando com brise retrátil amadeirado na sacada, estampam o recado de que o luxo faz morada aqui. Uma obra de arte com 282 m² construídos, piscina, churrasqueira e imponente pé direito duplo na sala de estar.",
    image: FioreHouse,
    imageMobile: FioreHouseMobile,
    isAvailable: "Disponível",
    fachada: Fiore1Lg,
    carouselData: [
      {
        image: Fiore3Lg,
        video: "",
      },
      {
        image: Fiore4Lg,
        video: "",
      },
      {
        image: Fiore5Lg,
        video: "",
      },
      {
        image: Fiore6Lg,
        video: "",
      },
      {
        image: Fiore7Lg,
        video: "",
      },
      {
        image: Fiore8Lg,
        video: "",
      },
      {
        image: Fiore9Lg,
        video: "",
      },
      {
        image: Fiore10Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostFiore1,
        video: "",
        url: "https://www.instagram.com/p/CqBjaRRvNvA/",
      },
      {
        image: PostFiore2,
        video: "",
        url: "https://www.instagram.com/p/CnQJ1r9PFF9/",
      },
      {
        image: PostPortoBelo1,
        video: "",
        url: "https://www.instagram.com/p/CkHL01uBxRo/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 25 | Lote 13",
    bedrooms: "4",
    bathrooms: "5",
    surface: "360m²",
    built: "282m²",
    price: "4091850",
    pricepage: "4.091.850",
    tooltip: "2 Suítes e 2 Demi Suítes",
    garage: "2",
    location: FioreLocation,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Churrasqueira planejada", "Selo Leed Platinum", "Sala com pé direito duplo", "Automações"],
    certificate: true,
    community: true,
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 3,
    type: "Casa",
    name: "Lumina",
    isAvailable: "Vendida",
    description:
      "A casa Lumina, obteve sua inspiração na imponencia de um diamante sendo lapidado e que reflete a luz ao seu redor. São 297 m² marcados por uma mistura de tons amadeirados, pedra natural e cimento, com uma iluminação moderna e detalhes de acabamento em led, além de um pé direito duplo magnífico com painel de vidro acompanhando uma marcação arredondada semelhante a lapidação da pedra preciosa. Contando com 4 suítes, sendo uma master, 6 banheiros, garagem para 4 carros, piscina com borda de vidro laminado e fire place.",
    image: LuminaHouse,
    imageMobile: LuminaHouseMobile,
    fachada: Lumina1Lg,
    carouselData: [
      {
        image: Lumina2Lg,
        video: "",
      },
      {
        image: Lumina3Lg,
        video: "",
      },
      {
        image: Lumina4Lg,
        video: "",
      },
      {
        image: Lumina5Lg,
        video: "",
      },
      {
        image: Lumina6Lg,
        video: "",
      },
      {
        image: Lumina7Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostLumina1,
        video: "",
        url: "https://www.instagram.com/p/CjBDheLrDo9/",
      },
      {
        image: PostPortoBelo1,
        video: "",
        url: "https://www.instagram.com/p/CkHL01uBxRo/",
      },
      {
        image: PostLumina2,
        video: "",
        url: "https://www.instagram.com/p/CjoT-rNP3mO/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 07 | Lote 03",
    bedrooms: "4",
    bathrooms: "6",
    surface: "377m²",
    built: "297m²",
    price: "4276800",
    pricepage: "4.276.800",
    tooltip: "3 Suítes e uma Suíte Master",
    garage: "2",
    location: LuminaLocation,
    certificate: true,
    community: true,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Amplo Jardim e área verde privativa", "Iluminação de led", "Selo Leed Platinum", "Pé direito duplo", "Acabamento premium com concreto aparente, textura e pedra natural"],
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 4,
    type: "Casa",
    name: "Reggia",
    isAvailable: "Disponível",
    description:
      "A casa Reggia foi criada a partir de um conceito arquitetônico único, inspirada na exuberante e imponente Vitória Régia, esta casa parece flutuar do solo em meio ao paisagismo e águas decorativas, destacando-se com suas linhas naturais e detalhes que encantam. São 488m² construídos através de um projeto exclusivo que conta com conceito aberto, integralizando ambientes, abundância de iluminação natural e ventilação cruzada. Casa com 4 suítes, sendo 1 suíte master, amplo espaço gourmet com piscina planejada, cozinha e sala de jantar integralizados com visão privilegiada para a natureza.",
    image: ReggiaHouse,
    imageMobile: ReggiaHouseMobile,
    fachada: Reggia1Lg,
    carouselData: [
      {
        image: Reggia2Lg,
        video: "",
      },
      {
        image: "",
        video: "https://player.vimeo.com/video/786489131?h=c082716626",
      },
      {
        image: Reggia3Lg,
        video: "",
      },
      {
        image: Reggia4Lg,
        video: "",
      },
      {
        image: Reggia5Lg,
        video: "",
      },
      {
        image: Reggia6Lg,
        video: "",
      },
      {
        image: Reggia7Lg,
        video: "",
      },
      {
        image: Reggia9Lg,
        video: "",
      },
      {
        image: Reggia10Lg,
        video: "",
      },
      {
        image: Reggia11Lg,
        video: "",
      },
      {
        image: Reggia12Lg,
        video: "",
      },
      {
        image: Reggia13Lg,
        video: "",
      },
      {
        image: Reggia14Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostReggia1,
        video: "",
        url: "https://www.instagram.com/p/CoYNDzXvQyx/",
      },
      {
        image: PostReggia2,
        video: "",
        url: "https://www.instagram.com/p/CjlrgSNvomH/",
      },
      {
        image: PostReggia3,
        video: "",
        url: "https://www.instagram.com/p/CjbblycPvf5/",
      },
      {
        image: PostPortoBelo1,
        video: "",
        url: "https://www.instagram.com/p/CkHL01uBxRo/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 29 | Lote 06",
    bedrooms: "4",
    bathrooms: "5",
    surface: "516m²",
    built: "488m²",
    price: "6859545",
    pricepage: "6.859.545",
    tooltip: "3 Suítes e uma Suíte Master",
    garage: "2",
    location: ReggiaLocation,
    certificate: true,
    community: true,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: [
      "Amplo Jardim e área verde privativa",
      "Selo Leed Platinum",
      "Casa elevada do solo em mezanino (1m)",
      "Acabamento premium com concreto aparente, textura e pedra natural",
      "Piso porcelanato 1,20m x 1,20m",
      "Automação de persianas",
      "Piso vinílico nas areas íntimas",
      "Captação de água da chuva e reutilização nos jardins",
      "Piscina em concreto armado e revestimento com passagens para aquecimento",
      "Tomada para carro elétrico na garagem",
      "Esquadrias em aluminio premium embutidas piso a teto",
    ],
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 5,
    type: "Duplex",
    name: "Vivendas",
    isAvailable: "Vendida",
    description: "",
    image: Vivendas,
    imageMobile: VivendasMobile,
    fachada: Vivendas1Lg,
    carouselData: [
      {
        image: Vivendas3Lg,
        video: "",
      },
      {
        image: Vivendas4Lg,
        video: "",
      },
      {
        image: Vivendas6Lg,
        video: "",
      },
      {
        image: Vivendas7Lg,
        video: "",
      },
      {
        image: Vivendas8Lg,
        video: "",
      },
      {
        image: Vivendas9Lg,
        video: "",
      },
      {
        image: Vivendas10Lg,
        video: "",
      },
      {
        image: Vivendas11Lg,
        video: "",
      },
      {
        image: Vivendas12Lg,
        video: "",
      },
      {
        image: Vivendas13Lg,
        video: "",
      },
      {
        image: Vivendas2Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostVivendas1,
        video: "",
        url: "https://www.instagram.com/p/Cilg93epBXg/",
      },
      {
        image: PostVivendas2,
        video: "",
        url: "https://www.instagram.com/p/Cn0Mq-Pvjld/",
      },
    ],
    country: "Bombinhas",
    address: "Rua Crispim 1235, Bombas.",
    bedrooms: "2",
    bathrooms: "3",
    surface: "83m²",
    built: "83m²",
    price: "520000",
    pricepage: "520.000",
    tooltip: "2 Suítes",
    garage: "1",
    location: VivendasLocation,
    certificate: false,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Conforto e praticidade", "Excelente Localização", "Selo Leed Platinum"],
    googleMap:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1055.4965198894868!2d-48.51752832637305!3d-27.148330178163786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8a44c85a6ec89%3A0x7ce470574682b87a!2sR.%20Crispim%2C%201235%20-%20Bombas%2C%20Bombinhas%20-%20SC%2C%2088215-000!5e0!3m2!1spt-BR!2sbr!4v1666359247439!5m2!1spt-BR!2sbr",
  },
  {
    id: 6,
    type: "Casa",
    name: "Oasis",
    isAvailable: "Consultar",
    description:
      "Um magnifico refúgio ao urbano. Esta é a casa Oasis, criada com linhas orgânicas e imponentes através do inconfundível traço do arquiteto Mauricio Melara. Esta obra de arte traz elementos marcante em seus 530m², com 4 suítes, garagens com espaço para 7 carros e amplos ambientes em conceito aberto, conversando com o coração do projeto: uma área de lazer paradisíaca.",
    image: OasisHouse,
    imageMobile: OasisHouseMobile,
    fachada: Oasis1Lg,
    carouselData: [
      {
        image: Oasis2Lg,
        video: "",
      },
      {
        image: Oasis3Lg,
        video: "",
      },
      {
        image: Oasis4Lg,
        video: "",
      },
      {
        image: Oasis5Lg,
        video: "",
      },
      {
        image: Oasis6Lg,
        video: "",
      },
      {
        image: Oasis7Lg,
        video: "",
      },
      {
        image: Oasis8Lg,
        video: "",
      },
      {
        image: Oasis9Lg,
        video: "",
      },
      {
        image: Oasis10Lg,
        video: "",
      },
      {
        image: Oasis11Lg,
        video: "",
      },
      {
        image: Oasis12Lg,
        video: "",
      },
      {
        image: Oasis13Lg,
        video: "",
      },
      {
        image: Oasis14Lg,
        video: "",
      },
      {
        image: Oasis15Lg,
        video: "",
      },
      {
        image: Oasis16Lg,
        video: "",
      },
      {
        image: Oasis17Lg,
        video: "",
      },
      {
        image: Oasis18Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostOasis1,
        video: "",
        url: "https://www.instagram.com/p/Cr1jpDwPUl1/",
      },
      {
        image: PostOasis2,
        video: "",
        url: "https://www.instagram.com/p/CpV2sIWPpOm/",
      },
      {
        image: PostOasis3,
        video: "",
        url: "https://www.instagram.com/p/CiizzDeJsFf/",
      },
      {
        image: PostPortoBelo1,
        video: "",
        url: "https://www.instagram.com/p/CkHL01uBxRo/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 04 | Lote 05-06",
    bedrooms: "4",
    bathrooms: "7",
    surface: "750m²",
    built: "530m²",
    price: "8989575",
    pricepage: "8.989.575",
    garage: "3",
    tooltip: "3 Suítes e uma Suíte Master",
    certificate: true,
    community: true,
    location: OasisLocation,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Acabamento extra premium com concreto aparente, madeira ecológica, alumínio e textura", "Selo Leed Platinum"],
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 7,
    type: "Casa",
    name: "Cerne",
    isAvailable: "Vendida",
    description:
      "Um projeto imponente e com linhas bem definidas que trazem o aconchego dos tons amadeirados em casamento perfeito com a força da pedra e metal",
    image: CerneHouse,
    imageMobile: CerneHouseMobile,
    fachada: CerneFachada,
    isNew: false,
    carouselData: [
      {
        image: Cerne1Lg,
        video: "",
      },
      {
        image: Cerne3Lg,
        video: "",
      },
      {
        image: Cerne4Lg,
        video: "",
      },
      {
        image: Cerne5Lg,
        video: "",
      },
      {
        image: Cerne6Lg,
        video: "",
      },
      {
        image: Cerne7Lg,
        video: "",
      },
      {
        image: Cerne8Lg,
        video: "",
      },
      {
        image: Cerne9Lg,
        video: "",
      },
      {
        image: Cerne10Lg,
        video: "",
      },
      {
        image: Cerne11Lg,
        video: "",
      },
      {
        image: Cerne12Lg,
        video: "",
      },
      {
        image: Cerne13Lg,
        video: "",
      },
      {
        image: Cerne14Lg,
        video: "",
      },
      {
        image: Cerne15Lg,
        video: "",
      },
      {
        image: Cerne16Lg,
        video: "",
      },
      {
        image: Cerne17Lg,
        video: "",
      },
      {
        image: Cerne18Lg,
        video: "",
      },
      {
        image: Cerne19Lg,
        video: "",
      },
      {
        image: Cerne20Lg,
        video: "",
      },
      {
        image: Cerne21Lg,
        video: "",
      },
      {
        image: Cerne22Lg,
        video: "",
      },
      {
        image: Cerne23Lg,
        video: "",
      },
      {
        image: Cerne24Lg,
        video: "",
      },
      {
        image: Cerne25Lg,
        video: "",
      },
      {
        image: Cerne26Lg,
        video: "",
      },
      {
        image: Cerne27Lg,
        video: "",
      },
      {
        image: Cerne28Lg,
        video: "",
      },
      {
        image: Cerne29Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostCerne1,
        video: "",
        url: "https://www.instagram.com/p/CmfM4mEvB80/",
      },
      {
        image: PostCerne2,
        video: "",
        url: "https://www.instagram.com/p/CsKEBpsOOAK/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 25 | Lote 17",
    bedrooms: "4",
    bathrooms: "5",
    surface: "360m²",
    built: "290m²",
    price: "4950900",
    pricepage: "4.950.900",
    tooltip: "4 Suítes",
    garage: "2",
    certificate: true,
    community: true,
    location: CerneLocation,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Soberana em cada traço", "Garagem para 4 carros", "Selo Leed Platinum", "Living com pé direito duplo", "Cozinha Multifuncional", "Amplo espaço Gourmet", "Piscina planejada"],
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 8,
    type: "Casa",
    name: "Briz",
    isAvailable: "Consultar",
    description:
      "Esta casa foi desenhada em tons praianos e leves, contemplando a fluidez de uma fachada que repousa sobre uma base de pedras naturais.",
    image: BrizHouse,
    imageMobile: BrizHouseMobile,
    fachada: BrizFachada,
    isNew: false,
    carouselData: [
      {
        image: Briz1Lg,
        video: "",
      },
      {
        image: Briz2Lg,
        video: "",
      },
      {
        image: Briz3Lg,
        video: "",
      },
      {
        image: Briz4Lg,
        video: "",
      },
      {
        image: Briz5Lg,
        video: "",
      },
      {
        image: Briz6Lg,
        video: "",
      },
      {
        image: Briz7Lg,
        video: "",
      },
      {
        image: Briz8Lg,
        video: "",
      },
      {
        image: Briz9Lg,
        video: "",
      },
      {
        image: Briz10Lg,
        video: "",
      },
      {
        image: Briz11Lg,
        video: "",
      },
      {
        image: Briz12Lg,
        video: "",
      },
      {
        image: Briz13Lg,
        video: "",
      },
      {
        image: Briz14Lg,
        video: "",
      },
      {
        image: Briz15Lg,
        video: "",
      },
      {
        image: Briz16Lg,
        video: "",
      },
      {
        image: Briz17Lg,
        video: "",
      },
      {
        image: Briz18Lg,
        video: "",
      },
      {
        image: Briz20Lg,
        video: "",
      },
      {
        image: Briz21Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostBriz1,
        video: "",
        url: "https://www.instagram.com/p/ClSCrvGvE0K/",
      },
      {
        image: PostBriz2,
        video: "",
        url: "https://www.instagram.com/p/Clze_elPVlq/",
      },
      {
        image: PostBriz3,
        video: "",
        url: "https://www.instagram.com/p/CmFddAAvWYx/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 29 | Lote 08",
    bedrooms: "4",
    bathrooms: "6",
    surface: "390m²",
    built: "361m²",
    price: "5381764",
    pricepage: "5.381.764",
    tooltip: "4 Suítes",
    garage: "2",
    certificate: true,
    community: true,
    location: BrizLocation,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Leve como uma brisa que vem do mar", "Garagem para 4 carros", "Selo Leed Platinum", "Home Office", "Elevador", "Amplo espaço Gourmet", "Piscina planejada"],
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 9,
    type: "Casa",
    name: "Corten",
    isAvailable: "Consultar",
    description:
      "Um projeto reservado ao externo, mas completamente aberto ao convívio",
    image: CortenHouse,
    imageMobile: CortenHouseMobile,
    fachada: CortenFachada,
    isNew: false,
    carouselData: [
      {
        image: Corten25Lg,
        video: "",
      },
      {
        image: Corten2Lg,
        video: "",
      },
      {
        image: Corten3Lg,
        video: "",
      },
      {
        image: Corten4Lg,
        video: "",
      },
      {
        image: Corten5Lg,
        video: "",
      },
      {
        image: Corten6Lg,
        video: "",
      },
      {
        image: Corten7Lg,
        video: "",
      },
      {
        image: Corten8Lg,
        video: "",
      },
      {
        image: Corten9Lg,
        video: "",
      },
      {
        image: Corten10Lg,
        video: "",
      },
      {
        image: Corten11Lg,
        video: "",
      },
      {
        image: Corten12Lg,
        video: "",
      },
      {
        image: Corten13Lg,
        video: "",
      },
      {
        image: Corten14Lg,
        video: "",
      },
      {
        image: Corten15Lg,
        video: "",
      },
      {
        image: Corten16Lg,
        video: "",
      },
      {
        image: Corten17Lg,
        video: "",
      },
      {
        image: Corten18Lg,
        video: "",
      },
      {
        image: Corten19Lg,
        video: "",
      },
      {
        image: Corten20Lg,
        video: "",
      },
      {
        image: Corten21Lg,
        video: "",
      },
      {
        image: Corten22Lg,
        video: "",
      },
      {
        image: Corten23Lg,
        video: "",
      },
      {
        image: Corten24Lg,
        video: "",
      },
      {
        image: Corten1Lg,
        video: "",
      },
      {
        image: Corten26Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostCorten1,
        video: "",
        url: "https://www.instagram.com/p/CkrbaYPvCE9/",
      },
      {
        image: PostCorten2,
        video: "",
        url: "https://www.instagram.com/p/Ck1l7aovQpP/",
      },
      {
        image: PostCorten3,
        video: "",
        url: "https://www.instagram.com/p/CrCAOUsPz4J/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 13 | Lote 16",
    bedrooms: "4",
    bathrooms: "4",
    surface: "360m²",
    built: "341m²",
    price: "5046090",
    pricepage: "5.046.090",
    tooltip: "4 Suítes",
    garage: "2",
    certificate: true,
    community: true,
    location: CortenLocation,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Sintonia entre sobriedade e imponência", "Garagem para 4 carros", "Selo Leed Platinum", "Living Integrado", "Amplo espaço Gourmet", "Piscina planejada"],
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 10,
    type: "Casa",
    name: "Natural",
    isAvailable: "Disponível",
    description:
      "",
    image: NaturalHouse,
    imageMobile: NaturalHouseMobile,
    fachada: NaturalFachada,
    isNew: false,
    carouselData: [
      {
        image: Natural1Lg,
        video: "",
      },
      {
        image: "",
        video: "https://player.vimeo.com/video/798271663?h=bfd9d8f018&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      },
      {
        image: Natural3Lg,
        video: "",
      },
      {
        image: Natural4Lg,
        video: "",
      },
      {
        image: Natural5Lg,
        video: "",
      },
      {
        image: Natural6Lg,
        video: "",
      },
      {
        image: Natural7Lg,
        video: "",
      },
      {
        image: Natural8Lg,
        video: "",
      },
      {
        image: Natural9Lg,
        video: "",
      },
      {
        image: Natural10Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostNatural1,
        video: "",
        url: "https://www.instagram.com/p/CpBRwuVPgDV/",
      },
      {
        image: PostNatural2,
        video: "",
        url: "https://www.instagram.com/p/Cp54DzmvklC/",
      },
      {
        image: PostNatural3,
        video: "",
        url: "https://www.instagram.com/p/Cq8z0AFvw8c/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo | Quadra 06 | Lote 02",
    bedrooms: "3",
    bathrooms: "5",
    surface: "360m²",
    built: "383m²",
    price: "5515488",
    pricepage: "5.515.488",
    garage: "2",
    tooltip: "3 Suítes",
    certificate: true,
    community: true,
    location: NaturalLocation,
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Selo Leed Platinum", "Piscina e Jardim Privativos", "Ambientes Integrados", "Home Office"],
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
  {
    id: 11,
    type: "Casa",
    name: "Cosmopolita",
    isAvailable: "Vendida",
    description:
      "Casa Cosmopolita: Moderna e surpreendente, os traços desenvolvidos pela Arquitetare trazem um conceito bem marcado através de um estilo urbano estampado em concreto aparente, grandes esquadrias e amplo espaço. A casa Cosmopolita carrega a ousadia que o sucesso necessita em cada detalhe dessa obra prima.",
    image: CosmoHouse,
    imageMobile: CosmoHouseMobile,
    fachada: CosmoFachada,
    isNew: true,
    carouselData: [
      {
        image: "",
        video: "https://player.vimeo.com/video/754460005?h=df7873feca&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      },
      {
        image: Cosmo1Lg,
        video: "",
      },
      {
        image: Cosmo2Lg,
        video: "",
      },
      {
        image: Cosmo3Lg,
        video: "",
      },
      {
        image: Cosmo4Lg,
        video: "",
      },
      {
        image: Cosmo5Lg,
        video: "",
      },
      {
        image: Cosmo6Lg,
        video: "",
      },
      {
        image: Cosmo7Lg,
        video: "",
      },
    ],
    carouselInsta: [
      {
        image: PostPortoBelo1,
        video: "",
        url: "https://www.instagram.com/p/CkHL01uBxRo/",
      },
    ],
    country: "Porto Belo",
    address: "Viva Park Porto Belo",
    bedrooms: "5",
    bathrooms: "6",
    surface: "407.68m²",
    built: "363.12m²",
    price: "5515488",
    pricepage: "5.515.488",
    garage: "2",
    tooltip: "4 Suítes",
    certificate: true,
    community: true,
    location: "",
    agent: {
      image: Agent2,
      name: "Representantes Polaris",
      phone: "47 3224-1847",
    },
    topics: ["Piscina e Jardim Privativos", "Ambientes Integrados", "Home Office"],
    googleMap:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3549.8367591680917!2d-48.606460634949755!3d-27.16142588302115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94d8aff4f5353045%3A0x1d2b70e6ba6b1ab1!2sVivapark%20Porto%20Belo!5e0!3m2!1spt-BR!2sbr!4v1666359103324!5m2!1spt-BR!2sbr",
  },
];


import { useState } from "react";
import { HiInformationCircle } from "react-icons/hi";

function ModernButton() {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <div className="relative">
      <button
        className="bg-[#141651] flex hover:bg-[#2fa8a5] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        onClick={() => setShowDetails(!showDetails)}
      >
        <span>Ver mais</span>
        <HiInformationCircle className="ml-2" />
      </button>
    </div>
  );
}

export default ModernButton;
